import React from 'react';
import LoadingSpinner from './loadingSpinner';
const Channels = React.lazy(() => import('./channels'));

const App = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const searchChangeHandler = (event) => setSearchTerm(event.target.value);

  return (
  <>
    <div className="shadow-md h-12 flex justify-center items-center fixed top-0 w-screen z-10 bg-black">
    <div className="App-header p-3"><img src="./favicon48.png" className="App-logo" alt="Web TV"/></div>
      <input 
        className="rounded p-5 h-8 w-7\/12 shadow"
        type="text" 
        placeholder="Filtriraj kanale" 
        value={searchTerm}
        onChange={searchChangeHandler}
      />
    </div>
    <React.Suspense fallback={<LoadingSpinner />}>
      <Channels className="mt-12" searchTerm={searchTerm} />
    </React.Suspense>
  </>
)};

export default App;
